import React from 'react';
import styled, { keyframes } from 'styled-components';

const load8 = keyframes`
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
`;

const StyledLoader = styled.div`
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border: 0.55em solid rgba(143, 102, 39, 0.5);
  border-left: 0.55em solid #8f6627;
  transform: translateZ(0);
  animation: ${load8} 0.55s infinite linear;
  border-radius: 50%;
  width: 5em;
  height: 5em;
`;

export default function Loader() {
  return <StyledLoader>loading...</StyledLoader>;
}
