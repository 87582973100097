import React from 'react';
import Layout from '../components/Layout';
import TopSection from '../components/TopSection';
import GalleryFeed from '../components/GalleryFeed';
import SEO from '../components/Seo';

export default function Gallery() {
  return (
    <>
      <SEO title="Gallery" />
      <Layout>
        <TopSection title="Gallery" />
        <GalleryFeed />
      </Layout>
    </>
  );
}
