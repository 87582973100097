import React, { useState } from 'react';
import styled from 'styled-components';
import Masonry from 'react-masonry-component';
import useGalleryQuery from '../hooks/useGalleryQuery';
import Loader from './Loader';

const MasonryWrapper = styled.div`
  max-width: 100rem;
  margin: 0 auto;
  padding: 3rem 2rem;
`;

const StyledMasonry = styled(Masonry)`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const MasonryListItem = styled.li`
  padding: 1.5rem 0;
  width: 100%;

  &:first-child {
    padding-top: 0;
  }

  img {
    width: 100%;
    height: auto;
  }

  @media screen and (min-width: 600px) {
    padding: 1.5rem;
    width: 50%;

    &:first-child {
      padding-top: 1.5rem;
    }
  }

  @media screen and (min-width: 1200px) {
    width: 33.33%;
  }
`;

export default function GalleryFeed() {
  const data = useGalleryQuery();
  const [layoutComplete, setLayoutComplete] = useState(false);

  return (
    <MasonryWrapper>
      <StyledMasonry
        elementType="ul" // default 'div'
        options={{ transitionDuration: 0 }}
        onLayoutComplete={() => setLayoutComplete(true)}
      >
        {!layoutComplete && (
          <li
            style={{
              paddingTop: '3rem',
              minHeight: '100vh',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Loader />
          </li>
        )}
        {data.map(item => (
          <MasonryListItem key={item?.asset?.url}>
            <a href={item?.asset?.url} target="_blank" rel="noreferrer">
              <img src={item?.asset?.url} alt="" />
            </a>
          </MasonryListItem>
        ))}
      </StyledMasonry>
    </MasonryWrapper>
  );
}
