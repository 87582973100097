import { useStaticQuery, graphql } from 'gatsby';

export default function useGalleryQuery() {
  const data = useStaticQuery(graphql`
    {
      allSanityGallery {
        edges {
          node {
            images {
              asset {
                url
              }
            }
          }
        }
      }
    }
  `);

  return data.allSanityGallery.edges[0].node.images;
}
